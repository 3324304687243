<template>
  <footer class="footer">
    <div class="footer__content">
      <div class="footer__container container">
        <div class="footer__contact">
          <router-link to="/" @click.native="scrollToTop">
            <img class="footer__logo" :src="logo" width="150" :alt="item.name" />
          </router-link>
          <template v-if="companyInfo.email">
            <span class="footer__contact-contact">{{ $t('getInTouch') }}:</span>
            <a class="footer__contact-email" :href="`mailto:${companyInfo.email}`"
              >{{ $t('email') }}: {{ companyInfo.email }}</a
            >
            <a class="footer__contact-phone" :href="`tel:${(companyInfo.phone || '').trim()}`"
              >{{ $t('phone') }}: {{ companyInfo.phone }}</a
            >
          </template>
        </div>

        <div class="footer__menu">
          <template v-for="(item, index) in navigation">
            <ActionComponent
              v-if="$path('action.name', item) !== $path('name', $route)"
              class="footer__menu-action"
              :class="{ 'footer__menu-action--empty': !item.action }"
              v-bind="item"
              :key="index"
            />
            <template v-if="item.items && item.items.length">
              <ActionComponent
                v-for="(nested, ni) in item.items"
                class="footer__menu-action footer__menu-action--indent"
                v-bind="nested"
                :key="index + '_' + ni"
              />
            </template>
          </template>
        </div>

        <div class="footer__policy footer__clickable">
          <a :href="`/api/legal/privacy/page/${item.id}`" target="_blank">
            {{ $t('privacyPolicy') }} / {{ $t('cookiePolicy') }}
          </a>

          <a href="#" @click.prevent="editConsents">{{ $t('editConsents') }}</a>
          <so-me-footer v-if="someCustomField.length !== 0" :value="someCustomField" />
          <div class="footer__copyright">
            &copy; {{ year }} -

            <span class="footer__bottom">
              {{ $t('pageMadeBy') }}
              <a
                id="https://www.kvass.no"
                :href="`https://www.kvass.no?referrer=${currentUrl}`"
                target="_blank"
                >Kvass</a
              >
            </span>
          </div>
        </div>
        <FooterContent
          v-if="customFooterContent.length"
          :value="customFooterContent"
          class="footer__custom-content"
        />
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import API from '@/api'
import { GetCustomField } from '@/utils'

import FooterContent from '@kvass/template-footer-content'
import ActionComponent from 'vue-elder-navigation/src/action'
import SoMeFooter from '@/components/SoMeFooter.vue'

export default {
  props: {
    navigation: Array,
    cookieManager: {
      type: Object,
      defualt: () => ({}),
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    ...mapState('Root', ['item']),
    customFooterContent() {
      if (!this.item) return

      const content = GetCustomField('footer-content-v2', this.item.customFields) || []
      if (content.some(i => Boolean(this.$path('content', i) || this.$path('image', i)))) {
        return content.map(i => {
          let base = ''
          if (i.image) base = `<img class="mb-4" style="max-width: 230px;" src=${i.image.url}>`
          if (i.content) base = `${base}<div> ${i.content} </div>`
          return base
        })
      }

      return GetCustomField('footer-content', this.item.customFields) || []
    },
    companyInfo() {
      if (!this.item) return
      return GetCustomField('company-info', this.item.customFields) || {}
    },
    someCustomField() {
      if (!this.item) return
      return GetCustomField('social-media', this.item.customFields) || []
    },

    currentUrl() {
      return window.location.origin
    },

    logo() {
      return API.logo
    },
  },
  methods: {
    editConsents() {
      this.cookieManager.show()
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
  },
  metaInfo() {
    return {
      script: [{ src: 'https://unpkg.com/@kvass/widgets@1.0.18/dist/contact.js' }],
    }
  },

  components: {
    ActionComponent,
    FooterContent,
    SoMeFooter,
  },
}
</script>

<style lang="scss">
.footer {
  background-color: var(--custom-background-color);
  color: var(--footer-text-color);

  .container {
    padding-inline: var(--section-padding-inline) !important;
    max-width: calc(1280px + calc(var(--section-padding-inline) * 2));
    margin-left: auto;
    margin-right: auto;
    padding-left: 0rem;
    padding-right: 0rem;
  }

  &__clickable {
    & > button:hover,
    & > a:hover {
      text-decoration: underline;
    }
  }

  &__logo {
    object-fit: contain;
    object-position: left;
    max-height: 50px;
    width: 100%;
  }

  &__content {
    padding-top: 2rem;
    padding-bottom: 4rem;
  }

  &__bottom {
    padding-block: 0.5rem;
    padding-top: 1rem;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;

      gap: 1rem;
      flex-wrap: wrap;
    }
    &-text {
      text-align: center;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 4rem;
    @include respond-below('tablet') {
      gap: 2rem;
      grid-template-columns: 1fr;
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__contact-contact {
    text-decoration: underline;
  }

  &__contact-email {
    width: fit-content;
    &:hover {
      text-decoration: underline;
    }
  }
  &__contact-phone {
    width: fit-content;
    &:hover {
      text-decoration: underline;
    }
  }

  $menuMargin: 1rem;

  &__custom-content {
    @include respond-above('tablet') {
      margin-top: $menuMargin;
    }
  }

  &__policy {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    white-space: nowrap;

    & > * {
      width: fit-content;
    }

    @include respond-above('tablet') {
      margin-top: $menuMargin;
    }
  }

  &__menu {
    .elder__navigation-component-icon-right {
      display: none;
    }

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;

    @include respond-above('tablet') {
      margin-left: auto;
      margin-top: $menuMargin;
    }

    .elder__navigation-component {
      padding: 0;
    }
    &-action {
      cursor: pointer;
      width: fit-content;
      background-color: transparent !important;
      color: inherit;
      font-weight: inherit;

      &:hover {
        text-decoration: underline;
        text-decoration-color: var(--primary);
        text-decoration-thickness: 2px;
        text-underline-offset: 5px;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;

    &[id='https://www.kvass.no'] {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -0.2rem;
        width: 100%;
        height: 2px;
        background-color: var(--primary);
      }
    }
  }

  p {
    margin-top: 0;
  }
}
</style>
