<template>
  <div
    id="app"
    :style="`--custom-background-color: ${customColor}; --custom-body-color: ${customBodyColor}`"
  >
    <div class="demo-indicator" v-if="isDemo">
      <FontAwesomeIcon icon="exclamation-circle" />
      <span>{{ $t('thisIsADemo') }}</span>
    </div>
    <div
      v-if="$route.meta.navigation !== false"
      class="nav"
      :class="[
        `nav--${view.toLowerCase()}`,
        { [`nav--${view.toLowerCase()}-expanded`]: isExpanded },
        { 'nav--expanded': isExpanded },
        { 'nav-is-hidden': !showNavbar && !isExpanded },
      ]"
    >
      <div class="container">
        <NavigationComponent
          :logo="logo"
          :items="items"
          :title="projectName"
          padding="20px 4rem"
          :action="redirect(['Page'], { scrollTo: 0 })"
          :isOpen.sync="isExpanded"
        />
      </div>
    </div>
    <transition mode="out-in" name="route">
      <router-view />
    </transition>
    <FooterComponent
      v-if="$route.meta.footer !== false"
      :navigation="items.filter(i => i.id !== 'social-media')"
      :cookie-manager="CM"
    />
    <AssetComponent />
  </div>
</template>

<script>
import { NavigationComponent } from 'vue-elder-navigation'
import { mapState, mapActions } from 'vuex'
import AssetComponent, { EventBus as AssetBus } from '@kvass/asset-manager'
import FooterComponent from '@/components/Footer'
import API from './api'
import BrowserApiMixin from '@/mixins/browser-api'
import loadCM from '@/gdpr'

export default {
  mixins: [BrowserApiMixin('app')],
  data() {
    return {
      isOnTop: true,
      customItems: [],
      isExpanded: false,
      showNavbar: true,
      CM: {},
    }
  },
  computed: {
    ...mapState('Root', ['item', 'promises']),
    logos() {
      return {
        light: API.logoInverted,
        dark: API.logo,
      }
    },
    isSingular() {
      return API.projectType === 'singular'
    },
    view() {
      if (!this.isSingular) return this.$path('name', this.$route) || ''
      return 'ProjectResidential'
    },
    logo() {
      return this.logos.dark
    },
    isDemo() {
      return this.item.demo || false
    },
    labels() {
      return KvassConfig.get('customLabels') || {}
    },

    customColor() {
      if (!this.$path('customFields.custom-color.show-color', this.item)) return 'var(--grey);'
      return this.$path('customFields.custom-color.color', this.item) || 'var(--grey);'
    },
    customBodyColor() {
      if (!this.$path('customFields.custom-color.show-contrast-color', this.item)) return 'black;'
      return this.$path('customFields.custom-color.contrast-color', this.item) || 'black;'
    },

    projectName() {
      return this.item.name || ''
    },

    seo() {
      return this.$path('customFields.seo', this.item) || {}
    },
    hasFlatfinder() {
      return this.$path('flatfinders.length', this.item)
    },
    hasStatsTotal() {
      return this.$path('stats.total', this.item)
    },
    hasResidentials() {
      return this.$path('$store.state.Residentials.item.docs.length')
    },
    hasAttachments() {
      if (!this.isSingular) return this.$path('media.attachments.length', this.item)
      return this.$path('$store.state.Residential.item.media.attachments.length')
    },
    brochure() {
      return this.$path('media.brochure', this.item) || []
    },
    brochureStrategy() {
      return KvassConfig.get('siteSettings.brochureDownloadStrategy') || 'direct'
    },
    posts() {
      return this.$path('posts', this.item) || []
    },

    gallery() {
      if (!this.isSingular) return this.$path('media.gallery', this.item) || []
      return this.$path('$store.state.Residential.item.media.gallery') || []
    },

    items() {
      return [...this.defaultItems, ...this.customItems].sort((a, b) => a.order - b.order)
    },
    addons() {
      return this.$path('item.metadata.addons') || []
    },
    redirectUrl() {
      return (
        this.$path('item.publishing.redirectUrl') &&
        `https://${this.$path('item.publishing.redirectUrl')}`
      )
    },

    defaultItems() {
      return [
        ...this.posts.map(i => {
          if (!this.$path('customFields.page-settings.display-in-menu', i)) return {}
          return {
            icon: ['far', 'arrow-right'],
            label: i.title,
            exact: true,
            action: this.redirect(['Post'], { params: { slug: i.slug }, forceRedirect: true }),
            order: (this.$path('customFields.page-settings.navigation-order', i) || 1) * 10 - 5,
            class: 'navigation-page',
            id: 'navigation-page',
          }
        }),

        {
          label: this.$t('getInTouch'),
          class: 'elder__navigation-component--primary',
          action: this.redirect('Contact'),
          order: 100,
          id: 'contact',
        },

        {
          icon: ['fab', 'linkedin'],
          id: 'social-media',
          action: this.$path('item.customFields.social-media.linkedin_url'),
          order: 101,
          target: '_blank',
        },
        {
          icon: ['fab', 'facebook'],
          id: 'social-media',
          action: this.$path('item.customFields.social-media.facebook_url'),
          order: 102,
          target: '_blank',
        },
        {
          icon: ['fab', 'instagram'],
          id: 'social-media',
          action: this.$path('item.customFields.social-media.instagram_url'),
          order: 103,
          target: '_blank',
        },

        {
          id: 'social-media',
          icon: ['fab', 'twitter'],

          action: this.$path('item.customFields.social-media.twitter_url'),
          order: 104,
          target: '_blank',
        },
      ]
        .filter(x => !('condition' in x) || x.condition)
        .filter(x => x.action || (x.items && x.items.length))
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
    redirect(name, options = { forceRedirect: false }) {
      return async function() {
        name = name instanceof Array ? name : [name]

        let { action, scrollTo, hash, params, forceRedirect } = options

        if (!name.includes(this.$route.name) || params?.slug != this.$route.query?.slug)
          await this.$router.push({ name: name[0], hash, params })
        else if (hash) {
          let target = document.querySelector(
            '.scroll-anchor--type-anchor.scroll-anchor--value-' + hash.substring(1),
          )
          if (!target) return
          target.scrollIntoView({ behavior: 'smooth' })
        }
        if (action) return this.action()
        if (scrollTo !== undefined) window.scrollTo(0, scrollTo)
      }
    },
  },
  created() {
    this.promise = this.fetch().then(() => (this.CM = loadCM(this)))
  },
  metaInfo() {
    return {
      titleTemplate: this.projectName
        ? `%s | ${this.$path('seo.title') || this.projectName}`
        : `${this.$t('loading', { resource: null })}...`,
      link: [
        { rel: 'preload', href: this.logos.dark, as: 'image' },
        { rel: 'preload', href: this.logos.light, as: 'image' },
        { rel: 'icon', href: API.favicon, type: 'image/png' },
      ],
    }
  },
  components: {
    NavigationComponent,
    FooterComponent,
    AssetComponent,
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.nav {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: get-layer('nav');
  background: white;
  box-shadow: 0 10px 15px -10px rgba(black, 0.05);
  color: $dark;
  transition: background 150ms ease-in;

  .elder__navigation-bars {
    color: $dark;
  }

  .elder__navigation-dropdown .elder-dropdown__content .elder__navigation-component {
    color: var(--vue-elder-text-color, #444) !important;
  }

  .elder__navigation {
    svg {
      display: none;
    }

    [data-id='contact'] {
      padding-right: 1.5rem;
      .elder__navigation-component {
        background-color: var(--primary) !important;
        color: var(--primary-contrast) !important;
      }
    }
    [data-id='social-media'] {
      display: flex;
      margin-left: 0.2rem;
      svg {
        display: initial !important;
      }
      .elder__navigation-component {
        padding: 0;
        &:after {
          display: none;
        }
      }
    }
    &-logo {
      .elder__navigation-component {
        &:hover {
          cursor: pointer;
        }
      }
    }

    &-actions {
      .elder__navigation-component {
        position: relative;
        transition-duration: 100ms;
        transition-timing-function: var(--easing-function);
        &:hover {
          cursor: pointer;
          background: none;

          &:after {
            content: '';
            background-color: var(--primary);
            width: calc(100% - 2rem);
            height: 2px;
            position: absolute;
            left: 1rem;
            bottom: 0.5rem;
          }
        }
      }
    }

    &--responsive {
      padding: 20px 0 !important;

      svg {
        display: block;
      }

      [data-id='social-media'],
      [data-id='contact'] {
        background: white !important;
      }

      [data-id='contact'] {
        padding-top: 1.5rem !important;
        .elder__navigation-component {
          margin: 0 auto;
          max-width: 500px;
          justify-content: center;
        }
      }

      [data-id='social-media'] {
        align-self: center;
        justify-self: center;
        padding: 1.5rem 0 !important;
        max-width: 50px;

        .elder__navigation-component {
          display: block;
          padding: 0 !important;
          // make icons bigger
          font-size: 1.5rem;
        }
      }
    }
  }

  &-bars {
    color: white;
  }

  &--expanded {
    height: 100vh;

    .container {
      padding: 0;
    }
    .elder__navigation-node {
      margin-left: 1rem;
    }
    .elder__navigation-bars {
      margin-right: 1rem;
    }

    .elder__navigation-actions {
      display: flex !important;
      flex-wrap: wrap;
      justify-content: center;

      .elder__navigation-node {
        background: var(--grey);
        padding: 0.5rem 2rem;
        &:nth-child(1) {
          padding-top: 2rem;
        }
      }
    }
    .elder__navigation-actions > * {
      margin-left: 0rem;
      flex-grow: 1;
      padding: 2rem 2rem;
      width: 100%;
    }
    .elder__navigation-component.elder__navigation-component--primary {
      justify-content: center;
    }
  }
}

.demo-indicator {
  background-color: var(--warning);
  color: white;

  padding: 0.5em 0.75em;

  font-size: 0.8em;
  letter-spacing: 0.5px;
  line-height: 1;

  svg {
    margin-right: 5px;
  }
}
</style>
