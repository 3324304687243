<template>
  <Loader :value="promises.fetch" theme="default" class="page">
    <Blocks :blocks="config" :customComponents="customComponents"></Blocks>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BrowserApiMixin from '@/mixins/browser-api'

// pagebuilder
import { Blocks } from '@kvass/pagebuilder'
import config from '@/config/page'

// custom components
import Page from '@/components/Page'

export default {
  mixins: [BrowserApiMixin('page')],
  computed: {
    ...mapState('Page', ['promises', 'item']),

    config() {
      return config.call(this)
    },
    customComponents() {
      return { ...Page }
    },
  },
  methods: {
    ...mapActions('Page', ['fetch']),
  },
  created() {
    this.fetch()
  },

  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },
  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.page {
  .hero {
    $height: 90vh;
    $resposiveHeight: 70vh;

    &__slider {
      .kpb-slider__slider {
        min-height: $height;
        @include respond-below('phone') {
          min-height: $resposiveHeight;
        }
      }

      iframe {
        min-height: 100vh;
        width: 1000vh;
      }
    }

    &__overlay {
      min-height: $height;
      
      @include respond-below('phone') {
        min-height: $resposiveHeight;
      }

      z-index: 2;
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: clamp(1rem, 1.4rem, 2rem);
      background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));

      h1,
      h2 {
        color: white !important;
      }

      h1 {
        font-size: clamp(2rem, 5vw, 7vh);
        margin-block: 2rem;
      }

      p, h2 {
        font-size: clamp(1.2rem, 4vw, 1.8rem) !important;
      }

      .elder-button {
        &--primary {
          border: none;
          background: white;
          color: $dark;
        }

        &--secondary {
          background: transparent;
          border-color: white;
          border-width: 2px;
        }
      }

      & > * {
        max-width: 1000px;
        margin: 0;
      }

      &-placement {
        &--center {
          align-items: center;
          justify-content: center;
          text-align: center;
          .kpb-text {
            text-align: center;
          }
        }
        &--left {
          text-align: left;

          @include respond-above('phone') {
            padding-inline: 3rem;
          }
        }
        &--none {
          display: none;
        }
      }

      &-button-wrapper {
        display: inline-flex;
        padding: 0;
        gap: 1rem 2rem;
        text-align: center;
        margin: 1rem 0;
      }
    }
  }
  .kpb-stats {
    position: relative;
    z-index: 2;
  }

  .project-portal-wrapper {
    @include respond-below('phone') {
      align-items: flex-start;
      gap: 0.5rem;
    }
  }
}
</style>
