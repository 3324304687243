<template>
  <div class="announcement" v-if="content.content || content.button">
    <div class="announcement__expanded" v-if="!decrease">
      <ButtonComponent
        class="announcement-decrease"
        :icon="['fal', 'times']"
        @click.stop="decrease = true"
      />
      <div class="announcement__content" v-html="content.content"></div>

      <ButtonComponent
        v-if="content.url && content.button"
        tag="a"
        :label="content.button"
        :href="content.url"
        class="announcement__button"
      />
    </div>
    <ButtonComponent
      v-else
      class="announcement-increase"
      :icon="['far', 'arrow-left']"
      @click.stop="decrease = false"
    />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        content: '',
      }),
    },
  },

  data() {
    return {
      decrease: false,
    }
  },

  computed: {
    content() {
      return this.data.content || {}
    },
  },
  methods: {},
}
</script>

<style lang="scss">
.announcement {
  position: fixed;
  right: 0;
  top: calc(var(--nav-height) + 40px);
  color: var(--primary-contrast);
  background-color: var(--primary);
  box-shadow: -1px 6px 6px 3px rgba(34, 34, 34, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 150px;
  z-index: get-layer('cta');

  @include respond-below('phone') {
    position: relative;

    box-shadow: none;
    top: initial;
    width: 100%;
  }

  &__expanded {
    padding: 2.5rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    max-width: 300px;

    @include respond-below('phone') {
      padding: 1.75rem 0;
      text-align: center;
    }
  }

  &__content {
    font-size: 1.3rem;
    h2,
    p {
      margin: 0rem;
      line-height: 1.2;
      font-weight: 600;
    }

    @include respond-below('phone') {
      max-width: unset;
      font-size: 1rem;
    }
  }

  &__button {
    background-color: var(--primary-contrast);
    color: var(--primary);

    border: unset;

    .elder-button__label {
      text-align: center;
    }
  }

  &-decrease {
    position: absolute !important;
    right: 0;
    top: 0;
    border: none !important;
    .elder-button {
      &__icon {
        padding: 0.5rem !important;
        svg {
          font-size: 1rem;
        }
      }
    }
    @include respond-below('phone') {
      display: none;
    }
  }
  &-increase {
    min-height: 150px;

    border: none !important;
    .elder-button {
      &__icon {
        display: flex;

        padding: 0.5rem !important;
        svg {
          font-size: 1.3rem;
        }
      }
    }
  }
}
</style>
